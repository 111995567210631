
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_471iz_290",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_471iz_298",
  "pagination-button": "awsui_pagination-button_14rmt_471iz_316",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_471iz_323",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_471iz_326",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_471iz_330",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_471iz_333",
  "tabs-tab": "awsui_tabs-tab_14rmt_471iz_338",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_471iz_348",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_471iz_359",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_471iz_370",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_471iz_370",
  "refresh": "awsui_refresh_14rmt_471iz_375",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_471iz_379",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_471iz_421",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_471iz_499",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_471iz_506",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_471iz_510",
  "root": "awsui_root_14rmt_471iz_514",
  "tabs": "awsui_tabs_14rmt_471iz_290",
  "tabs-content": "awsui_tabs-content_14rmt_471iz_552",
  "fit-height": "awsui_fit-height_14rmt_471iz_556",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_471iz_562",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_471iz_576",
  "with-paddings": "awsui_with-paddings_14rmt_471iz_576",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_471iz_587",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_471iz_598",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_471iz_602"
};
  