
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_9a36v_145",
  "button": "awsui_button_fvjdu_9a36v_185",
  "dots": "awsui_dots_fvjdu_9a36v_186",
  "button-disabled": "awsui_button-disabled_fvjdu_9a36v_214",
  "arrow": "awsui_arrow_fvjdu_9a36v_219",
  "page-number": "awsui_page-number_fvjdu_9a36v_229",
  "button-current": "awsui_button-current_fvjdu_9a36v_235",
  "page-item": "awsui_page-item_fvjdu_9a36v_252",
  "root-disabled": "awsui_root-disabled_fvjdu_9a36v_270"
};
  