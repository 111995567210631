import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet, usePut, AppLayout, BreadcrumbGroup, Button, Container, Grid, Header, Input, SpaceBetween, Textarea } from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'

export function Settings () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: settings } = useGet('/api/mio/settings')

  useEffect(() => {
    setFormValues(settings)
  }, [settings])

  const update = usePut('/api/mio/settings/update', formValues, (resp) => { navigate('/mio') })

  if (formValues != null) {
    return (
      <AppLayout
        name={formValues.name}
        breadcrumbs={
          <BreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'MIO', href: '/mio' },
              { text: 'Settings' }
            ]}
          />
        }
        contentHeader={
          <Header variant='h1'>
            MIO Settings
          </Header>
        }
        content={
          <Form
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='link' onClick={() => { navigate('/mio') }}>Cancel</Button>
                <Button formAction='submit' variant='primary' onClick={() => update()}>Save Changes</Button>
              </SpaceBetween>
            }
          >
            <Container>
              <SpaceBetween size='l'>
                <FormField label='Welcome Message *' field='welcomeMessage' stretch>
                  <Textarea
                    placeholder='Enter welcome message'
                    ariaRequired
                    value={formValues.welcomeMessage}
                    onChange={({ detail }) => setFormValues({ ...formValues, welcomeMessage: detail.value })}
                  />
                </FormField>
                <Grid
                  gridDefinition={[
                    { colspan: { default: 6 } },
                    { colspan: { default: 6 } }
                  ]}
                >
                  <FormField label='Model *' field='model'>
                    <Input
                      placeholder='Enter model'
                      ariaRequired
                      value={formValues.model}
                      onChange={({ detail }) => setFormValues({ ...formValues, model: detail.value })}
                    />
                  </FormField>
                  <FormField label='Temperature *' field='temperature'>
                    <Input
                      placeholder='Enter temperature'
                      ariaRequired
                      value={formValues.temperature}
                      onChange={({ detail }) => setFormValues({ ...formValues, temperature: detail.value })}
                    />
                  </FormField>
                </Grid>
                <FormField label='System Message *' field='systemMessage' stretch>
                  <Textarea
                    placeholder='Enter system message'
                    ariaRequired
                    rows={20}
                    value={formValues.systemMessage}
                    onChange={({ detail }) => setFormValues({ ...formValues, systemMessage: detail.value })}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        }
      />
    )
  }
}
