import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toTitleCase } from 'titlecase'
import { useGet, AppLayout, Box, Button, Grid, Header, Link, Pagination, Select, SpaceBetween, Table, TextFilter } from 'rad-framework-ui'
import { formatDate, formatDateTime } from '../common/utilities'

export function PassiveEnrollmentList () {
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [exportingEnrollments, setExportingEnrollments] = useState(false)
  const [exportingContacts, setExportingContacts] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const tribe = searchParams.get('tribe') ?? ''
  const { data: passiveEnrollments, count } = useGet(
    '/api/passive-enrollment' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&tribe=${tribe}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: exportedPassiveEnrollments } = useGet(
    exportingEnrollments
      ? '/api/passive-enrollment' +
      `?search=${encodeURIComponent(searchText)}` +
      `&status=${searchStatus}` +
      `&tribe=${tribe}` +
      '&limit=30000'
      : null
  )
  const { data: exportedContacts } = useGet(
    exportingContacts
      ? '/api/passive-enrollment' +
      `?search=${encodeURIComponent(searchText)}` +
      '&status=accepted' +
      `&tribe=${tribe}` +
      '&limit=30000'
      : null
  )
  const { data: tribeOptions } = useGet('/api/option/tribe?includeAll=true')

  const statusOptions = [
    { label: 'All Statuses', value: '' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Declined', value: 'declined' },
    { label: 'Escalated', value: 'escalated' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Issued', value: 'issued' }
  ]

  const getColor = (item) => {
    switch (item?.status) {
      case 'issued':
        return 'text-status-success'
      case 'accepted':
        return 'text-status-success'
      case 'rejected':
        return 'text-status-error'
      case 'escalated':
        return 'text-status-warning'
      case 'declined':
        return 'text-status-warning'
      default:
        return null
    }
  }

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  useEffect(() => {
    if (exportingEnrollments && exportedPassiveEnrollments != null) {
      exportEnrollmentsToExcel()
      setExportingEnrollments(false)
    }
  }, [exportingEnrollments, exportedPassiveEnrollments])

  useEffect(() => {
    if (exportingContacts && exportedContacts != null) {
      exportContactsToExcel()
      setExportingContacts(false)
    }
  }, [exportingContacts, exportedContacts])

  function exportEnrollmentsToExcel () {
    const data = exportedPassiveEnrollments.map(x => ([
      '',
      formatDate(x.created_at),
      formatDate(x.status_date),
      '',
      '',
      '',
      '',
      '',
      x.status_reason ?? '-',
      [x.student_first_name, x.student_middle_name, x.student_last_name].filter(z => z != null).join(' ') + ' - ' + formatDate(x.student_birthdate),
      '',
      '',
      '',
      x.id.toString(),
      [x.primary_guardian_first_name, x.primary_guardian_middle_name, x.primary_guardian_last_name].filter(z => z != null).join(' '),
      x.primary_guardian_emails.join('\n') ?? '-',
      x.primary_guardian_phones.join('\n') ?? '-',
      x.primary_guardian_permission_to_text ? 'Yes' : 'No',
      [x.secondary_guardian_first_name, x.secondary_guardian_middle_name, x.secondary_guardian_last_name].filter(z => z != null).join(' '),
      x.secondary_guardian_emails.join('\n') ?? '-',
      x.secondary_guardian_phones.join('\n') ?? '-',
      x.secondary_guardian_permission_to_text ? 'Yes' : 'No',
      [x.mailing_address_line1, x.mailing_address_line2].filter(y => y != null).join('\n') + '\n' + [x.mailing_address_city, x.mailing_address_state, x.mailing_address_zip].join(' '),
      x.district_name,
      x.status != null ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : 'Submitted'
    ]))
    const header = [
      'Status',
      'Date Submitted',
      'Status Date',
      'Date Entered',
      'HFO Staff',
      'Date Contacted',
      'Contact Method',
      'Date Accepted',
      'Reason',
      'Child(ren) Name and Birthdate',
      'Case Notes',
      'Disposition',
      'Action Taken',
      'Case Number',
      'Primary Guardian Name',
      'Primary Guardian Email',
      'Primary Guardian Phone',
      'Primary Guardian Permission to Text',
      'Secondary Guardian Name',
      'Secondary Guardian Email',
      'Secondary Guardian Phone',
      'Secondary Guardian Permission to Text',
      'Address',
      'School District',
      'Application Status'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_passiveenrollments_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  function exportContactsToExcel () {
    const data = exportedContacts.map(x => ([
      x.primary_guardian_first_name ?? '',
      x.primary_guardian_middle_name ?? '',
      x.primary_guardian_last_name ?? '',
      x.secondary_guardian_first_name ?? '',
      x.secondary_guardian_middle_name ?? '',
      x.secondary_guardian_last_name ?? '',
      [x.student_first_name, x.student_middle_name, x.student_last_name].filter(z => z != null).join(' '),
      formatDate(x.student_birthdate),
      x.id.toString(),
      x.district_name,
      x.mailing_address_line1 ?? '',
      x.mailing_address_line2 ?? '',
      x.mailing_address_city ?? '',
      x.mailing_address_state ?? '',
      x.mailing_address_zip ?? '',
      x.primary_guardian_phones[0] ?? '',
      x.primary_guardian_phones[1] ?? '',
      x.primary_guardian_phones[2] ?? '',
      x.primary_guardian_permission_to_text ? 'Yes' : 'No',
      x.primary_guardian_emails[0] ?? '',
      x.primary_guardian_emails[1] ?? '',
      x.primary_guardian_emails[2] ?? '',
      x.secondary_guardian_phones[0] ?? '',
      x.secondary_guardian_phones[1] ?? '',
      x.secondary_guardian_phones[2] ?? '',
      x.secondary_guardian_permission_to_text ? 'Yes' : 'No',
      x.secondary_guardian_emails[0] ?? '',
      x.secondary_guardian_emails[1] ?? '',
      x.secondary_guardian_emails[2] ?? ''
    ]))
    const header = [
      'Primary Guardian First Name',
      'Primary Guardian Middle Name',
      'Primary Guardian Last Name',
      'Secondary Guardian First Name',
      'Secondary Guardian Middle Name',
      'Secondary Guardian Last Name',
      'Child Name',
      'Child DOB',
      'Case Number',
      'District',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip',
      'Primary Guardian Phone 1',
      'Primary Guardian Phone 2',
      'Primary Guardian Phone 3',
      'Primary Guardian Permission to Text',
      'Primary Guardian Email 1',
      'Primary Guardian Email 2',
      'Primary Guardian Email 3',
      'Secondary Guardian Phone 1',
      'Secondary Guardian Phone 2',
      'Secondary Guardian Phone 3',
      'Secondary Guardian Permission to Text',
      'Secondary Guardian Email 1',
      'Secondary Guardian Email 2',
      'Secondary Guardian Email 3'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_accepted_passive_enrollment_contacts_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  if (
    passiveEnrollments != null &&
    tribeOptions != null
  ) {
    return (
      <AppLayout
        contentHeader={
          <Header
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={() => setExportingEnrollments(true)}>Export Passive Enrollments</Button>
                <Button onClick={() => setExportingContacts(true)}>Export Accepted Passive Enrollment Contacts</Button>
              </SpaceBetween>
            }
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
            description={
              <SpaceBetween size='xxs'>
                <div>You can search by passive enrollment ID, state testing number, student name, student birthdate, school district, guardian name, guardian phone, and mailing address.</div>
                <div>Exports are limited to 30,000 records.</div>
              </SpaceBetween>
            }
          >
            Passive Enrollments
          </Header>
        }
        content={
          <Table
            columnDefinitions={[
              {
                id: 'id',
                header: '#',
                cell: item => <Link href={`/admin/passive-enrollment/${item.id}`}>{highlightMatch(item.id.toString())}</Link>
              },
              {
                id: 'studentTestingNumber',
                header: 'State Testing Number',
                cell: item => <div>{highlightMatch(item.state_testing_number ?? '-')}</div>
              },
              {
                id: 'student',
                header: 'Student',
                cell: item => <div>{highlightMatch(item.student_first_name + ' ' + (item.student_middle_name ?? '') + ' ' + item.student_last_name)} - {highlightMatch(formatDate(item.student_birthdate))}</div>
              },
              {
                id: 'districtName',
                header: 'School District',
                cell: item => <div>{highlightMatch(item.district_name)}</div>
              },
              {
                id: 'status',
                header: 'Status',
                cell: item => <Box color={getColor(item)}>{toTitleCase(item.status ?? 'submitted')}</Box>
              },
              {
                id: 'createdAt',
                header: 'Created',
                cell: item => formatDateTime(item.created_at)
              }
              // {
              //   id: 'actions',
              //   cell: item => <Button disabled={item.status === 'accepted'} variant='inline-icon' iconName='edit' href={`/admin/application/${item.id}/edit?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`} />

              // }
            ]}
            items={passiveEnrollments}
            variant='full-page'
            filter={
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <TextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search participants'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.delete('page')
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <Select
                  onChange={({ detail }) => {
                    setSearchStatus(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('status')
                    } else {
                      searchParams.set('status', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={statusOptions}
                  selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                  placeholder='Choose status'
                />
                <Select
                  onChange={({ detail }) => {
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('tribe')
                    } else {
                      searchParams.set('tribe', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={tribeOptions}
                  selectedOption={tribeOptions.find((x) => x.value === tribe) ?? tribeOptions[0]}
                  placeholder='Choose a tribe'
                />
              </Grid>
            }
            pagination={
              <Pagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <Box textAlign='center' color='inherit'>
                No matches found.
              </Box>
            }
          />
        }
      />
    )
  }
}
