
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_1ykar_vhnt2_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1ykar_vhnt2_1",
  "root": "awsui_root_1ykar_vhnt2_180",
  "calendar-inner": "awsui_calendar-inner_1ykar_vhnt2_218",
  "calendar-header": "awsui_calendar-header_1ykar_vhnt2_222",
  "calendar-header-title": "awsui_calendar-header-title_1ykar_vhnt2_227",
  "calendar-next-btn": "awsui_calendar-next-btn_1ykar_vhnt2_235",
  "calendar-prev-btn": "awsui_calendar-prev-btn_1ykar_vhnt2_238",
  "calendar-grid": "awsui_calendar-grid_1ykar_vhnt2_241",
  "calendar-grid-dense": "awsui_calendar-grid-dense_1ykar_vhnt2_245",
  "calendar-grid-cell": "awsui_calendar-grid-cell_1ykar_vhnt2_253",
  "calendar-date-header": "awsui_calendar-date-header_1ykar_vhnt2_258",
  "calendar-date": "awsui_calendar-date_1ykar_vhnt2_258",
  "calendar-date-enabled": "awsui_calendar-date-enabled_1ykar_vhnt2_278",
  "calendar-date-current-page": "awsui_calendar-date-current-page_1ykar_vhnt2_288",
  "calendar-date-selected": "awsui_calendar-date-selected_1ykar_vhnt2_299",
  "calendar-date-current": "awsui_calendar-date-current_1ykar_vhnt2_288",
  "calendar-date-dense": "awsui_calendar-date-dense_1ykar_vhnt2_320",
  "date-inner": "awsui_date-inner_1ykar_vhnt2_328",
  "calendar-row": "awsui_calendar-row_1ykar_vhnt2_403",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_1ykar_vhnt2_407"
};
  