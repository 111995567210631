
    import './styles.scoped.css';
    export default {
  "segment__path": "awsui_segment__path_1edmh_1b6e4_161",
  "segment": "awsui_segment_1edmh_1b6e4_161",
  "segment__hover": "awsui_segment__hover_1edmh_1b6e4_188",
  "label": "awsui_label_1edmh_1b6e4_202",
  "root": "awsui_root_1edmh_1b6e4_216",
  "content": "awsui_content_1edmh_1b6e4_220",
  "content--small": "awsui_content--small_1edmh_1b6e4_220",
  "content--fit-height": "awsui_content--fit-height_1edmh_1b6e4_223",
  "content--without-labels": "awsui_content--without-labels_1edmh_1b6e4_226",
  "content--medium": "awsui_content--medium_1edmh_1b6e4_232",
  "content--large": "awsui_content--large_1edmh_1b6e4_244",
  "status-container": "awsui_status-container_1edmh_1b6e4_261",
  "chart-container": "awsui_chart-container_1edmh_1b6e4_265",
  "chart-container--fit-height": "awsui_chart-container--fit-height_1edmh_1b6e4_269",
  "chart-container-chart-plot": "awsui_chart-container-chart-plot_1edmh_1b6e4_274",
  "chart-container-chart-plot--fit-height": "awsui_chart-container-chart-plot--fit-height_1edmh_1b6e4_277",
  "inner-content": "awsui_inner-content_1edmh_1b6e4_283",
  "segment__highlight": "awsui_segment__highlight_1edmh_1b6e4_294",
  "segment--dimmed": "awsui_segment--dimmed_1edmh_1b6e4_309",
  "segment--highlighted": "awsui_segment--highlighted_1edmh_1b6e4_312",
  "label--dimmed": "awsui_label--dimmed_1edmh_1b6e4_329",
  "label--align-right": "awsui_label--align-right_1edmh_1b6e4_332",
  "label-text": "awsui_label-text_1edmh_1b6e4_340",
  "label__description": "awsui_label__description_1edmh_1b6e4_348",
  "label-line": "awsui_label-line_1edmh_1b6e4_355",
  "label--highlighted": "awsui_label--highlighted_1edmh_1b6e4_359",
  "popover-header": "awsui_popover-header_1edmh_1b6e4_363"
};
  