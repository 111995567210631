import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useConfirm, useGet, Alert, Box, Button, Container, Divider, Grid, Header, Input, RadioGroup, Select, SpaceBetween } from 'rad-framework-ui'
import { FormField } from '../common/FormField'

export function Children ({ item, formValues, setFormValues, suffixOptions, raceEthnicityOptions, tribeOptions, translate, canExclude }) {
  const { data: schoolOptions } = useGet('/api/option/school-district')

  const gradeOptions = [
    { value: 'Head Start', label: translate('Head Start') },
    { value: 'PreK', label: translate('PreK') },
    { value: 'Kindergarten', label: translate('Kindergarten') },
    { value: '1st', label: translate('1st') },
    { value: '2nd', label: translate('2nd') },
    { value: '3rd', label: translate('3rd') },
    { value: '4th', label: translate('4th') },
    { value: '5th', label: translate('5th') },
    { value: '6th', label: translate('6th') },
    { value: '7th', label: translate('7th') },
    { value: '8th', label: translate('8th') },
    { value: '9th', label: translate('9th') },
    { value: '10th', label: translate('10th') },
    { value: '11th', label: translate('11th') },
    { value: '12th', label: translate('12th') }
  ]

  if (schoolOptions != null) {
    const schoolDistrictOptions = schoolOptions
      .filter(x => x.value !== '')
      .sort((a, b) => {
        if (a.label === 'UNKNOWN') return 1
        if (b.label === 'UNKNOWN') return -1
        return a.label.localeCompare(b.label)
      })

    return (
      <Container
        header={
          <Header variant='h2'>
            {translate('Child Information')}
          </Header>
        }
      >
        <SpaceBetween size='s'>
          <Box color='text-body-secondary'>
            <SpaceBetween size='xxs'>
              <span>{translate('Use the following section to add each child in your household attending school, K-12th grade, who would like to participate in the Summer EBT for Children program. You can add as many school aged children as needed by selecting the "Add child" button found below this section.')}</span>
              <span>{translate('For this application, an 18 or 19 year old who graduated from high school this school year (2024-2025) is considered a child and should be entered in the Child Information section.')}</span>
            </SpaceBetween>
          </Box>
          <SpaceBetween size='xxs'>
            {formValues.children.map((item) =>
              <Editor
                key={`child-${item.id ?? item.uuid}`}
                item={item}
                formValues={formValues}
                setFormValues={setFormValues}
                gradeOptions={gradeOptions}
                suffixOptions={suffixOptions}
                raceEthnicityOptions={raceEthnicityOptions}
                schoolOptions={schoolOptions}
                tribeOptions={tribeOptions}
                translate={translate}
                canExclude={canExclude}
              />
            )}
          </SpaceBetween>
          {formValues.children.filter(x => x.homeSchool === true || x.otherSchool === true).length > 0 &&
            <>
              <Divider />
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, s: 3 } }
                ]}
              >
                <FormField label={translate('What school district do you live in?')} field='schoolDistrictId' required>
                  <Select
                    filteringType='auto'
                    selectedOption={
                      schoolDistrictOptions
                        .find(x => parseInt(x.value) === formValues.children.filter(x => x.homeSchool || x.otherSchool)[0].schoolDistrictId)
                      }
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value === '') {
                        const children = formValues.children.map(x => ({ ...x, schoolDistrictId: x.homeSchool || x.otherSchool ? null : x.schoolDistrictId }))
                        setFormValues({ ...formValues, children })
                      } else {
                        const children = formValues.children.map(x => ({ ...x, schoolDistrictId: x.homeSchool || x.otherSchool ? parseInt(detail.selectedOption.value) : x.schoolDistrictId }))
                        setFormValues({ ...formValues, children })
                      }
                    }}
                    options={schoolDistrictOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel={translate('Selected')}
                    placeholder={translate('Choose a school district')}
                    empty={translate('No matches found')}
                  />
                </FormField>
              </Grid>
            </>}
          <br />
          <Button
            onClick={() => {
              const children = formValues.children
              children.push({ uuid: uuidv4(), excluded: false })
              setFormValues({ ...formValues, children })
            }}
          >
            {translate('Add child')}
          </Button>
        </SpaceBetween>
      </Container>
    )
  }
}

function Editor ({ item, formValues, setFormValues, gradeOptions, suffixOptions, raceEthnicityOptions, schoolOptions, tribeOptions, translate, canExclude }) {
  const filteredSchoolOptions = schoolOptions.filter(x => x.name !== 'UNKNOWN')

  const confirmExclude = useConfirm(
    'Confirm exclude child from benefit recipients',
    'Please confirm this child should be excluded from benefit recipients. The child will still be included in the count of household members.',
    'Confirm',
    () => {
      const children = formValues.children
      item.excluded = true
      setFormValues({ ...formValues, children })
    })

  const selectedSchool = item.schoolDistrictId != null ? schoolOptions.find(x => x.value === item.schoolDistrictId.toString()) : null
  const schoolDataShare = selectedSchool != null && selectedSchool.dataSharing === true
  const schoolApplicationClosed = selectedSchool != null && selectedSchool.tribalArea === 'Muscogee' && selectedSchool.ebtProvider === 'Cherokee'

  return (
    <SpaceBetween size='l'>
      {item !== formValues.children[0] && <Divider />}
      <Grid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <FormField label={translate('First Name')} field={`child.${item.id ?? item.uuid}.firstName`} required>
          <Input
            placeholder={translate('Enter first name')}
            ariaRequired
            value={item.firstName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.firstName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </FormField>
        <FormField label={translate('Middle Name')} field={`child.${item.id ?? item.uuid}.middleName`}>
          <Input
            placeholder={translate('Enter middle name')}
            ariaRequired
            value={item.middleName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.middleName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </FormField>
        <FormField label={translate('Last Name')} field={`child.${item.id ?? item.uuid}.lastName`} required>
          <Input
            placeholder={translate('Enter last name')}
            ariaRequired
            value={item.lastName}
            onChange={({ detail }) => {
              const children = formValues.children
              item.lastName = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </FormField>
        <FormField label={translate('Suffix')} field={`child.${item.id ?? item.uuid}.suffix`}>
          <Select
            filteringType='auto'
            selectedOption={suffixOptions.find(x => x.value === item.suffix)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.suffix = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={suffixOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a suffix')}
            empty={translate('No matches found')}
          />
        </FormField>
      </Grid>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <FormField label={translate('Race / Ethnicity')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
          <Select
            filteringType='auto'
            selectedOption={raceEthnicityOptions.find(x => x.value === item.raceEthnicity)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.raceEthnicity = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={raceEthnicityOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a race / ethnicity')}
            empty={translate('No matches found')}
          />
        </FormField>
        {item.raceEthnicity === 'Native American' &&
          <FormField label={translate('Tribe')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
            <Select
              filteringType='auto'
              selectedOption={item.otherTribe ? { value: 'Other', label: translate('Other') } : tribeOptions.find(x => x.value === item.tribe)}
              onChange={({ detail }) => {
                const children = formValues.children
                if (detail.selectedOption.value === 'Other') {
                  item.otherTribe = true
                  item.tribe = null
                } else {
                  item.otherTribe = false
                  item.tribe = detail.selectedOption.value
                }
                setFormValues({ ...formValues, children })
              }}
              options={tribeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel={translate('Selected')}
              placeholder={translate('Choose a tribe')}
              empty={translate('No matches found')}
            />
          </FormField>}
        {item.otherTribe &&
          <FormField label={translate('If other tribe please specify')} field={`child.${item.id ?? item.uuid}.raceEthnicity`}>
            <Input
              placeholder={translate('Enter tribe')}
              value={item.tribe}
              onChange={({ detail }) => {
                const children = formValues.children
                item.tribe = detail.value
                setFormValues({ ...formValues, children })
              }}
            />
          </FormField>}
        <FormField label={translate('Birth Date')} field={`child.${item.id ?? item.uuid}.birthDate`} required>
          <Input
            ariaRequired
            type='date'
            placeholder={translate('Enter birth date')}
            value={item.birthDate}
            onChange={({ detail }) => {
              const children = formValues.children
              item.birthDate = detail.value
              setFormValues({ ...formValues, children })
            }}
          />
        </FormField>
      </Grid>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <FormField label={translate('Grade')} field={`child.${item.id ?? item.uuid}.grade`} required>
          <Select
            filteringType='auto'
            selectedOption={gradeOptions.find(x => x.value === item.grade)}
            onChange={({ detail }) => {
              const children = formValues.children
              item.grade = detail.selectedOption.value
              setFormValues({ ...formValues, children })
            }}
            options={gradeOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a grade')}
            empty={translate('No matches found')}
          />
          <Box color='text-status-inactive' fontSize='body-s' padding={{ top: 'xxs' }}>
            {translate('Select the grade for the most recent school year, not the grade they will be entering next year.')}
          </Box>
        </FormField>
        <FormField label={translate('School')} field={`child.${item.id ?? item.uuid}.schoolId`}>
          <Select
            filteringType='auto'
            disabled={item.homeSchool === true || item.otherSchool === true}
            disabledReason={translate('A school cannot be selected for a home school child')}
            selectedOption={(item.homeSchool === true || item.otherSchool === true) ? null : schoolOptions.find(x => x.value === `${item.schoolDistrictId}`)}
            onChange={({ detail }) => {
              const children = formValues.children
              if (detail.selectedOption.value === '') {
                item.schoolDistrictId = null
              } else {
                item.schoolDistrictId = parseInt(detail.selectedOption.value)
              }
              item.homeSchool = false
              item.otherSchool = false
              setFormValues({ ...formValues, children })
            }}
            options={filteredSchoolOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel={translate('Selected')}
            placeholder={translate('Choose a school')}
            empty={translate('No matches found')}
          />
        </FormField>
        <FormField label={translate('Home School?')} field={`child.${item.id ?? item.uuid}.homeSchool`} required>
          <RadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.homeSchool = detail.value
              if (detail.value === true) {
                item.otherSchool = false
                item.schoolDistrictId = formValues.children.find(x => (x.homeSchool || x.otherSchool) && x.schoolDistrictId != null)?.schoolDistrictId
              } else {
                item.schoolDistrictId = null
              }
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.homeSchool}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </FormField>
        <FormField label={translate('Other School?')} field={`child.${item.id ?? item.uuid}.otherSchool`} required>
          <RadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.otherSchool = detail.value
              if (detail.value === true) {
                item.homeSchool = false
                item.schoolDistrictId = formValues.children.find(x => (x.homeSchool || x.otherSchool) && x.schoolDistrictId != null)?.schoolDistrictId
              } else {
                item.schoolDistrictId = null
              }
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.otherSchool}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </FormField>
      </Grid>

      {(item.homeSchool === false && item.otherSchool === false && item.schoolDistrictId != null && (schoolApplicationClosed || schoolDataShare)) &&
        <Alert statusIconAriaLabel='Warning' type='warning'>{
          `${schoolApplicationClosed
            ? ' ' + translate('Applications associated with this school are no longer being accepted for benefits.')
            : ''}
          ${schoolDataShare
            ? ' ' + translate('This school is participating in data sharing for this program and your student may be automatically enrolled if they receive free or reduced priced meals.')
            : ''}`
        }
        </Alert>}

      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <FormField label={translate('Foster Child?')} field={`child.${item.id ?? item.uuid}.fosterChild`} required>
          <RadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.fosterChild = detail.value
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.fosterChild}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </FormField>
        <FormField label={translate('Homeless / Migrant / Runaway?')} field={`child.${item.id ?? item.uuid}.homelessMigrantRunaway`} required>
          <RadioGroup
            onChange={({ detail }) => {
              const children = formValues.children
              item.homelessMigrantRunaway = detail.value
              setFormValues({ ...formValues, children })
            }}
            ariaRequired
            value={item.homelessMigrantRunaway}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </FormField>
      </Grid>
      {canExclude &&
        <FormField label={translate('Exclude child from benefit recipients?')} field={`child.${item.id ?? item.uuid}.excluded`} required>
          <RadioGroup
            onChange={({ detail }) => {
              if (detail.value === true) {
                confirmExclude()
              } else {
                const children = formValues.children
                item.excluded = detail.value
                setFormValues({ ...formValues, children })
              }
            }}
            ariaRequired
            value={item.excluded}
            items={[
              { value: true, label: translate('Yes') },
              { value: false, label: 'No' }
            ]}
          />
        </FormField>}
      {formValues.children.length > 1 &&
        <Box>
          <Button
            wrapText={false}
            onClick={() => {
              const children = formValues.children.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, children })
            }}
          >
            {translate('Remove child')}
          </Button>
        </Box>}
    </SpaceBetween>
  )
}
