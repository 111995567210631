import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useConfirm, usePut, Box, Button, Checkbox, Container, Header, Select, SpaceBetween } from 'rad-framework-ui'
import { FormField } from '../common/FormField'

export function OverrideEditor ({ formValues, setFormValues, translate, showBypassMailingAddress, nodes }) {
  const ebtProviderOptions = [
    { value: '', label: '-' },
    { value: 'Cherokee', label: 'Cherokee' },
    { value: 'Chickasaw', label: 'Chickasaw' }
  ]

  const bypassMailingAddress = (
    <FormField field='bypassMailingAddress'>
      <Checkbox
        checked={formValues.bypassMailingAddress ?? false}
        onChange={({ detail }) => {
          setFormValues({ ...formValues, bypassMailingAddress: detail.checked })
        }}
      >
        Mailing address outside of Oklahoma, application should proceed.
      </Checkbox>
    </FormField>
  )

  const bypassPotentialDuplicate = (
    <FormField field='bypassPotentialDuplicate'>
      <Checkbox
        checked={formValues.bypassPotentialDuplicate ?? false}
        onChange={({ detail }) => {
          setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
        }}
      >
        Application is not a potential duplicate.
      </Checkbox>
    </FormField>
  )

  const schoolDistrictOverride = (
    <FormField label='EBT Provider' field='schoolDistrictOverride'>
      <Select
        filteringType='none'
        selectedOption={ebtProviderOptions.find(x => x.value === formValues.schoolDistrictOverride) ?? ebtProviderOptions[0]}
        onChange={({ detail }) => {
          if (detail.selectedOption.value === '') {
            setFormValues({ ...formValues, schoolDistrictOverride: null })
          } else {
            setFormValues({ ...formValues, schoolDistrictOverride: detail.selectedOption.value })
          }
        }}
        options={ebtProviderOptions}
        enteredTextLabel={value => value}
        selectedAriaLabel='Selected'
        placeholder='Choose a EBT provider'
        empty='No matches found'
      />
    </FormField>
  )

  return (
    <Container header={<Header variant='h2'>{translate('Admin Controls')}</Header>}>
      <SpaceBetween size='s'>
        {showBypassMailingAddress && bypassMailingAddress}
        {formValues.id != null && bypassPotentialDuplicate}
        {schoolDistrictOverride}
        {nodes}
      </SpaceBetween>
    </Container>
  )
}

export function OverrideEditorForm ({ application, translate }) {
  const originalValues = {
    id: application.id,
    bypassPotentialDuplicate: application.bypassPotentialDuplicate,
    schoolDistrictOverride: application.schoolDistrictOverride
  }
  const [formValues, setFormValues] = useState(originalValues)
  const navigate = useNavigate()
  const update = usePut(`/api/application/${application.id}/controls`, formValues, (resp) => { navigate(`/admin/application/${application.id}`) })
  const confirmUpdate = useConfirm(
    translate('Confirm Save Changes'),
    translate('Submitting these changes will remove the application\'s current status. Are you sure you want to proceed?'),
    translate('Save Changes'),
    () => { update() }
  )
  const currentStatus = application.statuses[0]?.status
  const isPristine = () => Object.keys(formValues).every(k => formValues[k] === originalValues[k])

  const saveButton = (
    <Button
      onClick={() => {
        if (application.statuses.length > 0) {
          confirmUpdate()
        } else {
          update()
        }
      }}
      formAction='submit'
      variant='normal'
      disabled={currentStatus === 'accepted' || isPristine()}
    >
      Save Changes
    </Button>
  )

  return (
    <Box padding={{ bottom: 'xxl' }}>
      <OverrideEditor
        formValues={formValues}
        setFormValues={setFormValues}
        translate={translate}
        showBypassMailingAddress={false}
        nodes={saveButton}
      />
    </Box>
  )
}
