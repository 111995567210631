
    import './styles.scoped.css';
    export default {
  "visible-content": "awsui_visible-content_tc96w_s4kk6_289",
  "visible-content-toggle": "awsui_visible-content-toggle_tc96w_s4kk6_290",
  "visible-content-groups": "awsui_visible-content-groups_tc96w_s4kk6_291",
  "visible-content-group": "awsui_visible-content-group_tc96w_s4kk6_291",
  "visible-content-title": "awsui_visible-content-title_tc96w_s4kk6_296",
  "visible-content-group-label": "awsui_visible-content-group-label_tc96w_s4kk6_306",
  "visible-content-option": "awsui_visible-content-option_tc96w_s4kk6_312",
  "visible-content-option-label": "awsui_visible-content-option-label_tc96w_s4kk6_325",
  "content-display-option-toggle": "awsui_content-display-option-toggle_tc96w_s4kk6_620",
  "content-display-option-content": "awsui_content-display-option-content_tc96w_s4kk6_624",
  "content-display-option": "awsui_content-display-option_tc96w_s4kk6_620",
  "content-display-option-label": "awsui_content-display-option-label_tc96w_s4kk6_672",
  "drag-handle-wrapper": "awsui_drag-handle-wrapper_tc96w_s4kk6_679",
  "content-display": "awsui_content-display_tc96w_s4kk6_620",
  "content-display-text-filter": "awsui_content-display-text-filter_tc96w_s4kk6_684",
  "content-display-no-match": "awsui_content-display-no-match_tc96w_s4kk6_685",
  "content-display-title": "awsui_content-display-title_tc96w_s4kk6_689",
  "content-display-description": "awsui_content-display-description_tc96w_s4kk6_698",
  "content-display-option-list": "awsui_content-display-option-list_tc96w_s4kk6_706",
  "root": "awsui_root_tc96w_s4kk6_713",
  "modal-root": "awsui_modal-root_tc96w_s4kk6_714",
  "trigger-button": "awsui_trigger-button_tc96w_s4kk6_715",
  "cancel-button": "awsui_cancel-button_tc96w_s4kk6_716",
  "confirm-button": "awsui_confirm-button_tc96w_s4kk6_717",
  "custom": "awsui_custom_tc96w_s4kk6_718",
  "content-before": "awsui_content-before_tc96w_s4kk6_719",
  "second-column-small": "awsui_second-column-small_tc96w_s4kk6_723",
  "wrap-lines": "awsui_wrap-lines_tc96w_s4kk6_727",
  "striped-rows": "awsui_striped-rows_tc96w_s4kk6_728",
  "content-density": "awsui_content-density_tc96w_s4kk6_729",
  "page-size": "awsui_page-size_tc96w_s4kk6_730",
  "page-size-form-field": "awsui_page-size-form-field_tc96w_s4kk6_731",
  "page-size-radio-group": "awsui_page-size-radio-group_tc96w_s4kk6_732",
  "sticky-columns": "awsui_sticky-columns_tc96w_s4kk6_733",
  "sticky-columns-form-field": "awsui_sticky-columns-form-field_tc96w_s4kk6_734",
  "sticky-columns-radio-group": "awsui_sticky-columns-radio-group_tc96w_s4kk6_735",
  "sticky-columns-first": "awsui_sticky-columns-first_tc96w_s4kk6_736",
  "sticky-columns-last": "awsui_sticky-columns-last_tc96w_s4kk6_737"
};
  