
    import './styles.scoped.css';
    export default {
  "drag-overlay": "awsui_drag-overlay_1ksmw_bv742_145",
  "drag-overlay-item": "awsui_drag-overlay-item_1ksmw_bv742_148",
  "drag-overlay-container": "awsui_drag-overlay-container_1ksmw_bv742_175",
  "active": "awsui_active_1ksmw_bv742_203",
  "placeholder": "awsui_placeholder_1ksmw_bv742_213",
  "placeholder-item": "awsui_placeholder-item_1ksmw_bv742_222",
  "placeholder-container": "awsui_placeholder-container_1ksmw_bv742_228",
  "sorting": "awsui_sorting_1ksmw_bv742_235"
};
  