import React, { useContext } from 'react'
import parse from 'html-react-parser'
import Form from '@cloudscape-design/components-themed/form'
import { AppContext } from 'rad-framework-ui'

// https://cloudscape.design/components/form
export function _Form ({ ...rest }) {
  const { error, translate } = useContext(AppContext)
  const errorText = error?.message ?? error?.code

  return (
    <Form
      errorText={errorText != null ? parse(translate(errorText)) : null}
      {...rest}
    />
  )
}

export { _Form as Form }
