
    import './styles.scoped.css';
    export default {
  "dropdown-content-wrapper": "awsui_dropdown-content-wrapper_qwoo0_18y9v_153",
  "awsui-motion-fade-in-dropdown": "awsui_awsui-motion-fade-in-dropdown_qwoo0_18y9v_1",
  "refresh": "awsui_refresh_qwoo0_18y9v_174",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_qwoo0_18y9v_1",
  "root": "awsui_root_qwoo0_18y9v_199",
  "interior": "awsui_interior_qwoo0_18y9v_232",
  "dropdown": "awsui_dropdown_qwoo0_18y9v_153",
  "use-portal": "awsui_use-portal_qwoo0_18y9v_242",
  "is-empty": "awsui_is-empty_qwoo0_18y9v_288",
  "dropdown-drop-up": "awsui_dropdown-drop-up_qwoo0_18y9v_295",
  "with-limited-width": "awsui_with-limited-width_qwoo0_18y9v_299",
  "dropdown-drop-left": "awsui_dropdown-drop-left_qwoo0_18y9v_302",
  "dropdown-drop-right": "awsui_dropdown-drop-right_qwoo0_18y9v_305",
  "occupy-entire-width": "awsui_occupy-entire-width_qwoo0_18y9v_308",
  "stretch-beyond-trigger-width": "awsui_stretch-beyond-trigger-width_qwoo0_18y9v_311",
  "hide-block-border": "awsui_hide-block-border_qwoo0_18y9v_318",
  "open": "awsui_open_qwoo0_18y9v_327",
  "nowrap": "awsui_nowrap_qwoo0_18y9v_336",
  "dropdown-content": "awsui_dropdown-content_qwoo0_18y9v_153",
  "stretch-trigger-height": "awsui_stretch-trigger-height_qwoo0_18y9v_347"
};
  