import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from './firebase'
import { AppContext, Badge, Box, TopNavigation } from 'rad-framework-ui'

// https://cloudscape.design/components/top-navigation
export function _TopNavigation () {
  const navigate = useNavigate()
  const { language, setLanguage, setPermissionDenied } = useContext(AppContext)
  const root = window.location.hostname.split('.')[0]
  let search
  switch (root) {
    case 'qa':
    case 'uat':
      search = <Box textAlign='center'><Badge color='green'>{root.toUpperCase()}</Badge></Box>
      break
    default:
  }

  const userProfile = [
    {
      type: 'menu-dropdown',
      text: auth.currentUser?.displayName,
      description: auth.currentUser?.email,
      iconName: 'user-profile',
      onItemClick: event => {
        switch (event.detail.id) {
          case 'signout':
            auth.signOut()
            setPermissionDenied(null)
            navigate('/')
            break
          default:
            console.log(`${event.detail.id} not implemented`)
        }
      },
      items: [
        { id: 'signout', text: 'Sign out' }
      ]
    },
    {
      type: 'menu-dropdown',
      text: language,
      // description: auth.currentUser?.email,
      // iconName: 'user-profile',
      onItemClick: event => { setLanguage(event.detail.id) },
      items: [
        { id: 'English', text: 'English' },
        { id: 'Spanish', text: 'Spanish' }
      ]
    }
  ]

  return (
    <TopNavigation
      identity={{
        href: '/admin',
        title: 'sEBT'
      }}
      utilities={auth.currentUser != null ? userProfile : []}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu'
      }}
      search={search}
    />
  )
}

export { _TopNavigation as TopNavigation }
