import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toTitleCase } from 'titlecase'
import {
  useConfirm,
  useGet,
  usePost,
  AppLayout,
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  Divider,
  Grid,
  Header,
  Input,
  Link,
  Modal,
  Select,
  SpaceBetween,
  stateList
} from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'
import { Notes } from '../common/Notes'
import { formatDate, formatDateTime } from '../common/utilities'

export function PassiveEnrollmentDetail () {
  const { passiveEnrollmentId } = useParams()
  const [reloadCounter, setReloadCounter] = useState(0)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [showMailingAddressModal, setShowMailingAddressModal] = useState(false)
  const [formValues, setFormValues] = useState()
  const { data: passiveEnrollment } = useGet(`/api/passive-enrollment/${passiveEnrollmentId}?reload=${reloadCounter}`, true)
  const { data: userInfo } = useGet('/api/user/current')
  const confirmReject = useConfirm('Reject Passive Enrollment?', 'Please confirm you would like to reject the passive enrollment?', 'Reject', () => { reject() })
  const reject = usePost(`/api/passive-enrollment/reject/${passiveEnrollmentId}`, {}, () => { setReloadCounter(reloadCounter + 1) })

  useEffect(() => {
    if (passiveEnrollment != null) {
      setFormValues({ ...passiveEnrollment, declined: passiveEnrollment.declinedAt != null })
    }
  }, [passiveEnrollment])

  if (
    passiveEnrollment != null &&
    formValues != null &&
    userInfo != null
  ) {
    const currentStatus = passiveEnrollment.declinedAt != null ? { status: 'declined', updatedAt: passiveEnrollment.updatedAt } : passiveEnrollment.statuses[0] ?? { status: 'submitted', updatedAt: passiveEnrollment.createdAt }
    const canReject = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Reject Passive Enrollment')) != null
    const canDecline = userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Decline Passive Enrollment')) != null

    return (
      <AppLayout
        name={passiveEnrollment.id}
        contentHeader={
          <Header
            variant='h1'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                {canReject && (currentStatus.status === 'escalated') &&
                  <Button onClick={confirmReject}>
                    Reject
                  </Button>}
                <Button
                  onClick={() => setShowAdminModal(true)}
                  disabled={currentStatus.status === 'accepted' || (currentStatus.status !== 'escalated' && !canDecline)}
                  disabledReason={
                    !canDecline
                      ? 'You do not have permission to decline or mark as a potential duplicate.'
                      : {
                          accepted: 'Passive enrollment cannot be declined or marked as a potential duplicate after it has been accepted.',
                          escalated: 'Passive enrollment cannot be declined or marked as a potential duplicate unless it has been escalated.'
                        }[currentStatus.status]
                  }
                >
                  Admin Controls
                </Button>
                <Button
                  onClick={() => setShowMailingAddressModal(true)}
                  disabled={currentStatus.status === 'issued' || currentStatus.status === 'rejected' || currentStatus.status === 'declined'}
                  disabledReason={
                    {
                      issued: 'Mailing address cannot be edited after a card has been issued.',
                      rejected: 'Mailing address cannot be edited if the passive enrollment has been rejected.',
                      declined: 'Mailing address cannot be edited if the passive enrollment has been declined.'
                    }[currentStatus.status]
                  }
                >
                  Edit Mailing Address
                </Button>
              </SpaceBetween>
            }
          >
            Passive Enrollment # {passiveEnrollment.id}
          </Header>
        }
        content={
          <SpaceBetween size='l'>
            <AdminControls
              passiveEnrollment={passiveEnrollment}
              formValues={formValues}
              setFormValues={setFormValues}
              showAdminModal={showAdminModal}
              setShowAdminModal={setShowAdminModal}
              currentStatus={currentStatus}
            />
            <MailingAddressEdit
              passiveEnrollment={passiveEnrollment}
              formValues={formValues}
              setFormValues={setFormValues}
              showMailingAddressModal={showMailingAddressModal}
              setShowMailingAddressModal={setShowMailingAddressModal}
              currentStatus={currentStatus}
            />
            <GeneralInformation passiveEnrollment={passiveEnrollment} />
            <Details passiveEnrollment={passiveEnrollment} />
            <Guardians passiveEnrollment={passiveEnrollment} />
          </SpaceBetween>
        }
        tools={<Notes entityType='passiveEnrollment' entityId={passiveEnrollmentId} />}
        toolsWidth='390'
      />
    )
  }
}

function AdminControls ({ passiveEnrollment, formValues, setFormValues, showAdminModal, setShowAdminModal, currentStatus }) {
  const admin = usePost(`/api/passive-enrollment/admin/${passiveEnrollment.id}`, formValues, (resp) => { setShowAdminModal(false) })
  const isDirty = formValues.declined !== (passiveEnrollment.declinedAt != null) || formValues.bypassPotentialDuplicate !== passiveEnrollment.bypassPotentialDuplicate

  return (
    <Modal
      size='large'
      onDismiss={() => {
        setShowAdminModal(false)
      }}
      visible={showAdminModal}
      header='Admin Controls'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => setShowAdminModal(false)}
              variant='link'
            >
              Cancel
            </Button>
            <Button
              onClick={() => admin()}
              variant='primary'
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {formValues != null &&
        <Form>
          <SpaceBetween size='s'>
            <FormField>
              <Checkbox
                checked={formValues.declined}
                onChange={({ detail }) => {
                  setFormValues({ ...formValues, declined: detail.checked })
                }}
              >
                Parent / Guardian declined benefits
              </Checkbox>
            </FormField>
            {currentStatus.status === 'escalated' &&
              <FormField>
                <Checkbox
                  checked={formValues.bypassPotentialDuplicate}
                  onChange={({ detail }) => {
                    setFormValues({ ...formValues, bypassPotentialDuplicate: detail.checked })
                  }}
                >
                  Passive Enrollment is not a potential duplicate
                </Checkbox>
              </FormField>}
          </SpaceBetween>
        </Form>}
    </Modal>
  )
}

function MailingAddressEdit ({ passiveEnrollment, formValues, setFormValues, showMailingAddressModal, setShowMailingAddressModal, currentStatus }) {
  const updateMailingAddress = usePost(`/api/passive-enrollment/update-mailing-address/${passiveEnrollment.id}`, formValues, (resp) => { setShowMailingAddressModal(false) })
  const isDirty = formValues.mailingAddressLine1 !== passiveEnrollment.mailingAddressLine1 ||
    formValues.mailingAddressLine2 !== passiveEnrollment.mailingAddressLine2 ||
    formValues.mailingAddressCity !== passiveEnrollment.mailingAddressCity ||
    formValues.mailingAddressState !== passiveEnrollment.mailingAddressState ||
    formValues.mailingAddressZip !== passiveEnrollment.mailingAddressZip

  return (
    <Modal
      size='medium'
      onDismiss={() => {
        setShowMailingAddressModal(false)
      }}
      visible={showMailingAddressModal}
      header='Edit Mailing Address'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => setShowMailingAddressModal(false)}
              variant='link'
            >
              Cancel
            </Button>
            <Button
              onClick={() => updateMailingAddress()}
              variant='primary'
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween size='s'>
          <div>
            <FormField label='Mailing Address' field='mailingAddressLine1' stretch required>
              <Input
                ariaRequired
                placeholder='Enter line 1'
                value={formValues.mailingAddressLine1}
                onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressLine1: detail.value })}
              />
            </FormField>
            <FormField label='Mailing Address line 2' field='mailingAddressLine2' stretch screenReader>
              <Input
                placeholder='Enter line 2'
                value={formValues.mailingAddressLine2}
                onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressLine2: detail.value })}
              />
            </FormField>
            <Grid
              gridDefinition={[
                { colspan: 5 },
                { colspan: 4 },
                { colspan: 3 }
              ]}
            >
              <FormField label='City' field='mailingAddressCity' required>
                <Input
                  ariaRequired
                  placeholder='Enter city'
                  value={formValues.mailingAddressCity}
                  onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressCity: detail.value })}
                />
              </FormField>
              <FormField label='State' field='mailingAddressState' required>
                <Select
                  filteringType='auto'
                  placeholder='Choose a state'
                  options={stateList}
                  selectedOption={stateList.find(x => x.value === formValues.mailingAddressState)}
                  onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressState: detail.selectedOption.value })}
                />
              </FormField>
              <FormField label='ZIP' field='mailingAddressZip' required>
                <Input
                  ariaRequired
                  placeholder='Enter zip'
                  type='number'
                  value={formValues.mailingAddressZip}
                  onChange={({ detail }) => setFormValues({ ...formValues, mailingAddressZip: detail.value })}
                />
              </FormField>
            </Grid>
          </div>
        </SpaceBetween>
      </Form>
    </Modal>
  )
}

function GeneralInformation ({ passiveEnrollment }) {
  const currentStatus = passiveEnrollment.declinedAt != null ? { status: 'declined', updatedAt: passiveEnrollment.updatedAt } : passiveEnrollment.statuses[0] ?? { status: 'submitted', updatedAt: passiveEnrollment.createdAt }
  const getColor = (item) => {
    switch (item?.status) {
      case 'issued':
        return 'text-status-success'
      case 'accepted':
        return 'text-status-success'
      case 'rejected':
        return 'text-status-error'
      case 'escalated':
        return 'text-status-warning'
      case 'declined':
        return 'text-status-warning'
      default:
        return null
    }
  }

  return (
    <Container
      header={
        <Header variant='h2'>
          General Information
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <ColumnLayout columns={3} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Created
            </Box>
            {formatDateTime(passiveEnrollment.createdAt)}
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Status
            </Box>
            <SpaceBetween size='s'>
              <Box display='inline' color={getColor(currentStatus)}>
                {toTitleCase(currentStatus.status)} {currentStatus.reason != null && ` - ${currentStatus.reason}`}
                <br />
                {formatDateTime(currentStatus.updatedAt)}
              </Box>
              {passiveEnrollment.bypassPotentialDuplicate && <div>Bypassed Potential Duplicate</div>}
            </SpaceBetween>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Upload ID
            </Box>
            {passiveEnrollment.passiveEnrollmentUploadId}
          </div>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  )
}

function Details ({ passiveEnrollment }) {
  const makeSearchTarget = (arr) => encodeURIComponent(arr.filter(s => s != null).join(' '))

  return (
    <Container
      header={
        <Header variant='h2'>
          Details
        </Header>
      }
    >
      <ColumnLayout columns={4} borders='vertical'>
        <div>
          <Box variant='awsui-key-label'>
            Student ID
          </Box>
          <div>{passiveEnrollment.studentId ?? '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            State Testing Number
          </Box>
          <div>{passiveEnrollment.stateTestingNumber ?? '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Student Name
          </Box>
          <div>{`${passiveEnrollment.studentFirstName} ${passiveEnrollment.studentMiddleName ? passiveEnrollment.studentMiddleName + ' ' : ''} ${passiveEnrollment.studentLastName}`}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Student Birth Date
          </Box>
          <div>{formatDate(passiveEnrollment.studentBirthdate)}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Student Grade
          </Box>
          <div>{passiveEnrollment.grade}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            School
          </Box>
          <div>{passiveEnrollment.schoolName}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            District
          </Box>
          <div>{passiveEnrollment.districtName}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Mailing Address
          </Box>
          <Link href={`/admin/passive-enrollment?search=${makeSearchTarget([passiveEnrollment.mailingAddressLine1, passiveEnrollment.mailingAddressLine2, passiveEnrollment.mailingAddressCity, passiveEnrollment.mailingAddressState, passiveEnrollment.mailingAddressZip])}`}>
            <div>{passiveEnrollment.mailingAddressLine1}</div>
            <div>{passiveEnrollment.mailingAddressLine2}</div>
            <div>{passiveEnrollment.mailingAddressCity}, {passiveEnrollment.mailingAddressState} {passiveEnrollment.mailingAddressZip}</div>
          </Link>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Physical Address
          </Box>
          {passiveEnrollment.physicalAddressLine1 == null &&
            passiveEnrollment.physicalAddressLine2 == null &&
            passiveEnrollment.physicalAddressCity == null &&
            passiveEnrollment.physicalAddressState == null &&
            passiveEnrollment.physicalAddressZip == null && '-'}
          <div>{passiveEnrollment.physicalAddressLine1}</div>
          <div>{passiveEnrollment.physicalAddressLine2}</div>
          <div>{passiveEnrollment.physicalAddressCity}{passiveEnrollment.physicalAddressCity != null && ','} {passiveEnrollment.physicalAddressState} {passiveEnrollment.physicalAddressZip}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Language
          </Box>
          <div>{passiveEnrollment.language ?? '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Preferred Contact Method
          </Box>
          <div>{passiveEnrollment.prefContactMethod ?? '-'}</div>
        </div>
        <div>
          <Box variant='awsui-key-label'>
            Certification Method
          </Box>
          <div>{passiveEnrollment.certificationMethod ?? '-'}</div>
        </div>
      </ColumnLayout>
    </Container>
  )
}

function Guardians ({ passiveEnrollment }) {
  const makeSearchTarget = (arr) => encodeURIComponent(arr.filter(s => s != null).join(' '))

  return (
    <Container
      header={
        <Header variant='h2'>
          Guardians
        </Header>
      }
    >
      <div>
        <ColumnLayout columns={4} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Primary
            </Box>
            <div>
              <Link href={`/admin/passive-enrollment?search=${makeSearchTarget([passiveEnrollment.primaryGuardianFirstName, passiveEnrollment.primaryGuardianMiddleName, passiveEnrollment.primaryGuardianLastName])}`}>
                <div>{`${passiveEnrollment.primaryGuardianFirstName} ${passiveEnrollment.primaryGuardianMiddleName ? passiveEnrollment.primaryGuardianMiddleName + ' ' : ''} ${passiveEnrollment.primaryGuardianLastName}`}</div>
              </Link>
            </div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Emails
            </Box>
            <div>
              {passiveEnrollment.primaryGuardianEmails.map((x, i) => <div key={'primary-email-' + i}>{x}</div>)}
              <div>{passiveEnrollment.primaryGuardianEmails.length === 0 && '-'}</div>
            </div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Phones
            </Box>
            <SpaceBetween size='xxs'>
              <div>
                {passiveEnrollment.primaryGuardianPhones.map((x, i) =>
                  <Link key={'primary-phone-' + i} href={`/admin/passive-enrollment?search=${makeSearchTarget([x])}`}>
                    <div>{x}</div>
                  </Link>
                )}
              </div>
              <div>{passiveEnrollment.primaryGuardianPhones.length === 0 && '-'}</div>
            </SpaceBetween>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Permission to Text
            </Box>
            <div>
              {passiveEnrollment.primaryGuardianPermissionToText ? 'Yes' : 'No'}
            </div>
          </div>
        </ColumnLayout>
        <Divider />
        <ColumnLayout columns={4} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Secondary
            </Box>
            <div>
              <Link href={`/admin/passive-enrollment?search=${makeSearchTarget([passiveEnrollment.secondaryGuardianFirstName, passiveEnrollment.secondaryGuardianMiddleName, passiveEnrollment.secondaryGuardianLastName])}`}>
                <div>{`${passiveEnrollment.secondaryGuardianFirstName} ${passiveEnrollment.secondaryGuardianMiddleName ? passiveEnrollment.secondaryGuardianMiddleName + ' ' : ''} ${passiveEnrollment.secondaryGuardianLastName}`}</div>
              </Link>
            </div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Emails
            </Box>
            <div>
              {passiveEnrollment.secondaryGuardianEmails.map((x, i) => <div key={'secondary-email-' + i}>{x}</div>)}
              <div>{passiveEnrollment.secondaryGuardianEmails.length === 0 && '-'}</div>
            </div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Phones
            </Box>
            <SpaceBetween size='xxs'>
              <div>
                {passiveEnrollment.secondaryGuardianPhones.map((x, i) =>
                  <Link key={'secondary-phone-' + i} href={`/admin/passive-enrollment?search=${makeSearchTarget([x])}`}>
                    <div>{x}</div>
                  </Link>
                )}
              </div>
              <div>{passiveEnrollment.secondaryGuardianPhones.length === 0 && '-'}</div>
            </SpaceBetween>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Permission to Text
            </Box>
            <div>
              {passiveEnrollment.secondaryGuardianPermissionToText ? 'Yes' : 'No'}
            </div>
          </div>
        </ColumnLayout>
      </div>
    </Container>
  )
}
