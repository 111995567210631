import React from 'react'

export function LineBreakRenderer ({ text, ...rest }) {
  return (
    <div {...rest}>
      {
        text.includes('\n')
          ? text.split('\n')
            .map((x, idx) => { return <React.Fragment key={idx}>{x}<br /></React.Fragment> })
          : text
      }
    </div>
  )
}
