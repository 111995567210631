
    import './styles.scoped.css';
    export default {
  "handle": "awsui_handle_sdha6_1q7hk_145",
  "handle-size-normal": "awsui_handle-size-normal_sdha6_1q7hk_153",
  "handle-size-small": "awsui_handle-size-small_sdha6_1q7hk_157",
  "handle-drag-indicator": "awsui_handle-drag-indicator_sdha6_1q7hk_160",
  "handle-resize-area": "awsui_handle-resize-area_sdha6_1q7hk_166",
  "handle-resize-horizontal": "awsui_handle-resize-horizontal_sdha6_1q7hk_173",
  "handle-resize-vertical": "awsui_handle-resize-vertical_sdha6_1q7hk_176",
  "handle-disabled": "awsui_handle-disabled_sdha6_1q7hk_179",
  "hide-focus": "awsui_hide-focus_sdha6_1q7hk_189",
  "resize-icon": "awsui_resize-icon_sdha6_1q7hk_211",
  "resize-icon-vertical": "awsui_resize-icon-vertical_sdha6_1q7hk_217",
  "resize-icon-horizontal": "awsui_resize-icon-horizontal_sdha6_1q7hk_221"
};
  