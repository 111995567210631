import React from 'react'
import { AdaptiveList, Link } from 'rad-framework-ui'

export function UserList () {
  return (
    <AdaptiveList
      entity='user'
      rootHref='/admin'
      fields={[
        { header: 'Name', name: 'name', link: true },
        'email',
        { header: 'Tribes', content: (item) => item.tribes.length > 0 ? item.tribes.map((x) => x.name).join(', ') : '-' },
        { header: 'Role', content: (item) => item.roles.length > 0 ? <Link href={`/admin/role/${item.roles[0].id}`}>{item.roles[0].name}</Link> : '-' }
      ]}
    />
  )
}
