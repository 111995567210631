import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useConfirm, useGet, usePut, Alert, AppContext, AppLayout, Box, Button, Checkbox, Container, Header, SpaceBetween } from 'rad-framework-ui'
import { Form } from '../common/Form'
import { FormField } from '../common/FormField'
import { isNullOrWhitespace } from '../common/utilities'
import { Children } from './Children'
import { HouseholdEditor } from './HouseholdEditor'
import { HouseholdMembers } from './HouseholdMembers'
import { PrimaryContactEditor } from './PrimaryContactEditor'
import { SecondaryContact } from './SecondaryContact'
import { Footer } from './Footer'
import { OverrideEditor, OverrideEditorForm } from './OverrideEditor'
import './Application.scss'

export function ApplicationEdit () {
  const { user, language, translate } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { applicationId } = useParams()
  const { data: application } = useGet(applicationId ? `/api/application/${applicationId}` : '')
  const { data: userInfo } = useGet('/api/user/current')
  const [formValues, setFormValues] = useState()
  const update = usePut(`/api/application/${applicationId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/admin/application/${applicationId}`) })
  const confirmUpdate = useConfirm(
    translate('Confirm Save Changes'),
    translate('Submitting this edit will remove the application\'s current status. Are you sure you want to proceed?'),
    translate('Save Changes'),
    () => { update() }
  )

  useEffect(() => {
    if (application != null) {
      const none = application.household.snap === false && application.household.tanf === false && application.household.fdpir === false && application.household.medicaid === false
      const otherLanguageSpoken = languageOptions.find(x => x.value === application.household.otherLanguageSpoken) == null
      const household = { ...application.household, none, otherLanguageSpoken }
      const physicalAddressSameAsMailing =
        application.primaryContact.mailingAddress.line1 === application.primaryContact.physicalAddress.line1 &&
        application.primaryContact.mailingAddress.line2 === application.primaryContact.physicalAddress.line2 &&
        application.primaryContact.mailingAddress.city === application.primaryContact.physicalAddress.city &&
        application.primaryContact.mailingAddress.state === application.primaryContact.physicalAddress.state &&
        application.primaryContact.mailingAddress.zip === application.primaryContact.physicalAddress.zip
      const primaryContact = { ...application.primaryContact, physicalAddressSameAsMailing }
      setFormValues({ ...application, primaryContact, household, agreeEsignature2: false, iCertify: false, signature2: null, consentToEditOnBehalfOf: false })
    }
  }, [application])

  useEffect(() => {
    if (formValues != null) {
      setFormValues({ ...formValues, language })
    }
  }, [language])

  function getSuffixOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Jr', label: translate('Jr') },
      { value: 'Sr', label: translate('Sr') },
      { value: 'II', label: translate('II') },
      { value: 'III', label: translate('III') },
      { value: 'IV', label: translate('IV') }
    ]
  }

  function getPhoneTypeOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Home', label: translate('Home') },
      { value: 'Work', label: translate('Work') },
      { value: 'Mobile', label: translate('Mobile') }
    ]
  }

  function getRaceEthnicityOptions () {
    return [
      { value: '', label: '-' },
      { value: 'Asian', label: translate('Asian') },
      { value: 'Black', label: translate('Black') },
      { value: 'Hispanic', label: translate('Hispanic') },
      { value: 'White', label: translate('White') },
      { value: 'Multiple', label: translate('Multiple') },
      { value: 'Native American', label: translate('Native American') },
      { value: 'Other', label: translate('Other') }
    ]
  }

  const tribeOptions = [
    { value: '', label: '-' },
    { value: 'Alabama Quassarte Tribal Town', label: 'Alabama Quassarte Tribal Town' },
    { value: 'Absentee Shawnee Tribe', label: 'Absentee Shawnee Tribe' },
    { value: 'Caddo Nation', label: 'Caddo Nation' },
    { value: 'Cherokee Nation', label: 'Cherokee Nation' },
    { value: 'Chickasaw Nation', label: 'Chickasaw Nation' },
    { value: 'Choctaw Nation', label: 'Choctaw Nation' },
    { value: 'Citizen Potawatomi Nation', label: 'Citizen Potawatomi Nation' },
    { value: 'Comanche Nation', label: 'Comanche Nation' },
    { value: 'Delaware Nation', label: 'Delaware Nation' },
    { value: 'Eastern Shawnee Tribe', label: 'Eastern Shawnee Tribe' },
    { value: 'Fort Sill Apache Tribe', label: 'Fort Sill Apache Tribe' },
    { value: 'Iowa Tribe', label: 'Iowa Tribe' },
    { value: 'Kaw Nation', label: 'Kaw Nation' },
    { value: 'Kickapoo Tribe', label: 'Kickapoo Tribe' },
    { value: 'Kiowa Tribe', label: 'Kiowa Tribe' },
    { value: 'Miami Tribe', label: 'Miami Tribe' },
    { value: 'Muscogee (Creek) Nation', label: 'Muscogee (Creek) Nation' },
    { value: 'Osage Nation', label: 'Osage Nation' },
    { value: 'Otoe-Missouria Tribe', label: 'Otoe-Missouria Tribe' },
    { value: 'Ottawa Tribe', label: 'Ottawa Tribe' },
    { value: 'Pawnee Nation', label: 'Pawnee Nation' },
    { value: 'Peoria Tribe of Indians', label: 'Peoria Tribe of Indians' },
    { value: 'Ponca Nation', label: 'Ponca Nation' },
    { value: 'Quapaw Tribe', label: 'Quapaw Tribe' },
    { value: 'Sac & Fox Nation', label: 'Sac & Fox Nation' },
    { value: 'Seminole Nation', label: 'Seminole Nation' },
    { value: 'Seneca-Cayuga Tribe', label: 'Seneca-Cayuga Tribe' },
    { value: 'Shawnee Tribe', label: 'Shawnee Tribe' },
    { value: 'Tonkawa Tribe', label: 'Tonkawa Tribe' },
    { value: 'United Keetoowah Band of Cherokees', label: 'United Keetoowah Band of Cherokees' },
    { value: 'Wichita & Affiliated Tribes', label: 'Wichita & Affiliated Tribes' },
    { value: 'Wyandotte Nation', label: 'Wyandotte Nation' },
    { value: 'Other', label: 'Other' }
  ]

  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' }
  ]

  const roleOptions = [
    { value: 'Parent / Guardian', label: translate('Parent / Guardian') },
    { value: 'Non-student child', label: translate('Non-student child') },
    { value: 'Other household member', label: translate('Other household member') }
  ]

  if (
    formValues != null &&
    userInfo != null
  ) {
    const hasStatuses = application.statuses.length > 0
    const currentStatus = hasStatuses && application.statuses[0].status
    const canExclude =
      (!hasStatuses || currentStatus === 'escalated' || currentStatus === 'needs verification' || currentStatus === 'verified' || currentStatus === 'rejected') &&
      (userInfo.roles.find(x => x.name === 'Admin') != null || userInfo.roles.find(x => x.permissions.find(y => y.name === 'Exclude Child from Benefit Recipients')) != null)
    const allChildrenExcluded = formValues.children.every(x => x.excluded === true)

    const saveChangesButton = (
      <Button
        onClick={() => {
          if (formValues.statuses.length > 0) {
            confirmUpdate()
          } else {
            update()
          }
        }}
        formAction='submit'
        variant='primary'
        disabled={
          (currentStatus === 'accepted') ||
          isNullOrWhitespace(formValues.signature2) ||
          formValues.agreeEsignature2 !== true ||
          formValues.concentToSignOnBehalfOf !== true ||
          allChildrenExcluded === true
        }
      >
        {translate('Save Changes')}
      </Button>
    )

    return (
      <AppLayout
        name={application.id}
        contentHeader={
          <Header variant='h1'>
            {translate('Application')} # {application.id}
          </Header>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween size='s'>
                  {allChildrenExcluded &&
                    <Alert
                      statusIconAriaLabel='Error'
                      type='error'
                    >
                      All children on this application have been excluded from benefits. If this is accurate, click Cancel and then Reject the application.
                      If this is not accurate, ensure the appropriate child(ren) are marked "No" under "Exclude child from benefit recipients" and click Save Changes.
                    </Alert>}
                  <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                      <Button variant='link' onClick={() => navigate(searchParams.get('redirectURL') ?? `/admin/application/${applicationId}`)}>Cancel</Button>
                      {user == null || formValues.consentToEditOnBehalfOf === true
                        ? saveChangesButton
                        : null}
                    </SpaceBetween>
                  </Box>
                </SpaceBetween>
              }
            >
              <SpaceBetween size='l'>
                {user != null &&
                  <Box padding={{ top: 'xxl' }} textAlign='center'>
                    <FormField field='consentToEditOnBehalfOf'>
                      {formValues.consentToEditOnBehalfOf === false &&
                        <OverrideEditorForm application={application} translate={translate} />}
                      <Checkbox
                        checked={formValues.consentToEditOnBehalfOf ?? false}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, consentToEditOnBehalfOf: detail.checked })
                        }}
                      >
                        {translate('I have the applicant\'s permission to edit this application on their behalf.')}
                      </Checkbox>
                    </FormField>
                  </Box>}

                {(user == null || formValues.consentToEditOnBehalfOf === true) &&
                  <>
                    {/* Override */}
                    <OverrideEditor
                      formValues={formValues}
                      setFormValues={setFormValues}
                      translate={translate}
                      showBypassMailingAddress
                    />

                    {/* Primary Contact Information */}
                    <Container
                      header={
                        <Header variant='h2'>
                          {translate('Primary Contact Information')}
                        </Header>
                      }
                    >
                      <PrimaryContactEditor
                        primaryContact={formValues.primaryContact}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        suffixOptions={getSuffixOptions()}
                        phoneTypeOptions={getPhoneTypeOptions()}
                        raceEthnicityOptions={getRaceEthnicityOptions()}
                        roleOptions={roleOptions}
                        tribeOptions={tribeOptions}
                        translate={translate}
                      />
                    </Container>

                    <SecondaryContact
                      secondaryContact={formValues.secondaryContact}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      suffixOptions={getSuffixOptions()}
                      phoneTypeOptions={getPhoneTypeOptions()}
                      translate={translate}
                    />

                    {/* Household Information */}
                    <Container
                      header={
                        <Header
                          variant='h2'
                        >
                          {translate('Household Information')}
                        </Header>
                      }
                    >
                      <HouseholdEditor
                        household={formValues.household}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        translate={translate}
                      />
                    </Container>

                    <Children
                      formValues={formValues}
                      setFormValues={setFormValues}
                      suffixOptions={getSuffixOptions()}
                      raceEthnicityOptions={getRaceEthnicityOptions()}
                      tribeOptions={tribeOptions}
                      translate={translate}
                      canExclude={canExclude}
                    />

                    <HouseholdMembers
                      formValues={formValues}
                      setFormValues={setFormValues}
                      suffixOptions={getSuffixOptions()}
                      raceEthnicityOptions={getRaceEthnicityOptions()}
                      roleOptions={roleOptions}
                      tribeOptions={tribeOptions}
                      translate={translate}
                    />

                    <ConsentAndSignature
                      formValues={formValues}
                      setFormValues={setFormValues}
                      translate={translate}
                    />

                    <Footer translate={translate} />
                  </>}
              </SpaceBetween>
            </Form>
          </form>
        }
      />
    )
  }
}

function ConsentAndSignature ({ formValues, setFormValues, translate }) {
  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          {translate('Before submitting your application, please review the following information.')}
        </Header>
      }
    >
      <SpaceBetween size='s'>
        <Box color='text-body-secondary'>
          <SpaceBetween size='xxs'>
            <span>{translate('The Richard B. Russell National School Lunch Act requires the information on this application. You do not have to give the information, but if you do not, we cannot approve your child for free and reduced-price meals. We will use your information to determine if your child is eligible for Summer EBT benefits.')}</span>
            <span>{translate('The Summer EBT program MAY share your eligibility information with education, health, and nutrition programs to help them evaluate, fund, or determine benefits for their programs, auditors for program reviews, and law enforcement officials to help them look into violations of program rules.')}</span>
            <span>{translate('Foster, migrant, homeless, and runaway children, and children enrolled in a Head Start program, are categorically eligible for free meals and free milk. If you are completing an application for these children, contact the school for more information.')}</span>
            <span>{translate('Participation in this program will have no impact on other government assistance you and your child receive.')}</span>
          </SpaceBetween>
        </Box>
        <FormField field='iCertify' stretch>
          <Checkbox
            checked={formValues.iCertify}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, iCertify: detail.checked })
            }}
          >
            {translate('I certify that all information furnished in the application is true and correct, that the application is being made in connection with the receipt of Federal funds, that school officials may verify the information on the application, and that deliberate misrepresentation of the information may subject me to prosecution under applicable State and Federal criminal statutes.')}
          </Checkbox>
        </FormField>
        <FormField field='agreeEsignature2' stretch>
          <Checkbox
            checked={formValues.agreeEsignature2 ?? false}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, agreeEsignature2: detail.checked })
            }}
          >
            {translate('Electronically signing this form shall be considered as valid as a physical signature.')}
          </Checkbox>
        </FormField>
        <FormField field='concentToSignOnBehalfOf' stretch>
          <Checkbox
            checked={formValues.concentToSignOnBehalfOf ?? false}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, concentToSignOnBehalfOf: detail.checked })
            }}
          >
            {translate('I have the applicant\'s permission to electronically sign this application on their behalf.')}
          </Checkbox>
        </FormField>
        <FormField label={translate('eSignature')} field='primaryContact.signature2' description={translate('Type your full name below to electronically sign.')}>
          <div className='signature-container'>
            <input
              className='signature'
              aria-required
              value={formValues.signature2 ?? ''}
              onChange={e => {
                setFormValues({ ...formValues, signature2: e.target.value })
              }}
            />
          </div>
        </FormField>
      </SpaceBetween>
    </Container>
  )
}
