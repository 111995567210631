import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet, useDelete, useConfirm, AppLayout, Header, Cards, Avatar, Box, Button, Link, SpaceBetween } from 'rad-framework-ui'

export function RoleDetail () {
  const navigate = useNavigate()
  const { roleId } = useParams()
  const { data: role } = useGet(`/api/role/${roleId}`)
  const confirmDelete = useConfirm('Delete role?', 'Delete role permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/role/${roleId}`, () => { navigate('/admin/role') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (role) {
    return (
      <AppLayout
        name={role.name}
        contentHeader={
          <Header
            variant='h1'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={edit}>Edit</Button>
                <Button onClick={confirmDelete} disabled={role.isProtected}>Delete</Button>
              </SpaceBetween>
            }
            description={
              (role.permissions.length > 0
                ? role.permissions.map((x) => x.name).join(', ')
                : role.name === 'Admin' ? '' : 'No permissions added to this role.')
            }
          >
            {role.name}
          </Header>
        }
        disableContentHeaderOverlap={role.users.length === 0}
        content={
          <Cards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionRoleLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={item.name}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <Link fontSize='heading-m' href={`/admin/user/${item.id}`}>{item.name}</Link>
                </>
              ),
              sections: [
                {
                  id: 'email',
                  content: item => item.email
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={role.users}
            variant='full-page'
            empty={
              <Box textAlign='center' color='inherit' padding={{ top: 'l' }}>
                <Box
                  variant='p'
                  color='inherit'
                >
                  No users added to this role.
                </Box>
              </Box>
            }
          />
        }
      />
    )
  }
}
