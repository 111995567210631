
    import './styles.scoped.css';
    export default {
  "badge": "awsui_badge_1yjyg_h1oq4_145",
  "badge-color-grey": "awsui_badge-color-grey_1yjyg_h1oq4_188",
  "badge-color-green": "awsui_badge-color-green_1yjyg_h1oq4_191",
  "badge-color-blue": "awsui_badge-color-blue_1yjyg_h1oq4_194",
  "badge-color-red": "awsui_badge-color-red_1yjyg_h1oq4_197",
  "badge-color-severity-critical": "awsui_badge-color-severity-critical_1yjyg_h1oq4_200",
  "badge-color-severity-high": "awsui_badge-color-severity-high_1yjyg_h1oq4_204",
  "badge-color-severity-medium": "awsui_badge-color-severity-medium_1yjyg_h1oq4_208",
  "badge-color-severity-low": "awsui_badge-color-severity-low_1yjyg_h1oq4_212",
  "badge-color-severity-neutral": "awsui_badge-color-severity-neutral_1yjyg_h1oq4_216"
};
  