import React from 'react'
import { Flashbar } from 'rad-framework-ui'

export function SiteBanner ({ message }) {
  const items = [
    {
      type: 'info',
      content: message,
      id: 'message_0'
    }
  ]

  return (
    <div className='site-banner'>
      <Flashbar items={items} />
    </div>
  )
}
