import React from 'react'
import { Box, SpaceBetween } from 'rad-framework-ui'

export function Footer ({ translate }) {
  return (
    <footer>
      <Box color='text-body-secondary' padding='l'>
        <SpaceBetween size='s'>
          <span>{translate('In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color, national origin, sex (including gender identity and sexual orientation), disability, age, or reprisal or retaliation for prior civil rights activity.')}</span>
          <span>{translate('Program information may be made available in languages other than English. Persons with disabilities who require alternative means of communication to obtain program information (e.g., Braille, large print, audiotape, American Sign Language), should contact the responsible state or local agency that administers the program or USDA’s TARGET Center at 202-720-2600 (voice and TTY) or contact USDA through the Federal Relay Service at 800-877-8339.')}</span>
          <span>{translate('To file a program discrimination complaint, a Complainant should complete a Form AD-3027, USDA Program Discrimination Complaint Form which can be obtained online at')}: <a href='https://www.usda.gov/sites/default/files/documents/ad-3027s.pdf' target='_blank' rel='noreferrer'>https://www.usda.gov/sites/default/files/documents/ad-3027s.pdf</a>, {translate('from any USDA office, by calling 866-632-9992, or by writing a letter addressed to USDA. The letter must contain the complainant’s name, address, telephone number, and a written description of the alleged discriminatory action in sufficient detail to inform the Assistant Secretary for Civil Rights (ASCR) about the nature and date of an alleged civil rights violation. The completed AD-3027 form or letter must be submitted to USDA by')}:</span>
          <div>
            <div>1. {translate('Mail')}:</div>
            <div>U.S. Department of Agriculture</div>
            <div>Office of the Assistant Secretary for Civil Rights</div>
            <div>1400 Independence Avenue, SW</div>
            <div>Washington, D.C. 20250-9410; {translate('or')}</div>
          </div>
          <div>
            <div>2. {translate('Fax')}:</div>
            <div>833-256-1665 {translate('or')} 202-690-7442; {translate('or')}</div>
          </div>
          <div>
            <div>3. {translate('Email')}:</div>
            <div>Program.Intake@usda.gov</div>
          </div>
          <Box textAlign='center'>{translate('This institution is an equal opportunity provider.')}</Box>
        </SpaceBetween>
      </Box>
    </footer>
  )
}
