
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_l1o8l_161",
  "has-items": "awsui_has-items_dm8gx_l1o8l_192",
  "no-padding": "awsui_no-padding_dm8gx_l1o8l_192",
  "dismiss-button": "awsui_dismiss-button_dm8gx_l1o8l_196",
  "token": "awsui_token_dm8gx_l1o8l_238",
  "token-box": "awsui_token-box_dm8gx_l1o8l_245",
  "token-box-readonly": "awsui_token-box-readonly_dm8gx_l1o8l_265",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_l1o8l_279"
};
  