
    import './styles.scoped.css';
    export default {
  "marker": "awsui_marker_1kjc7_1yzbm_153",
  "root": "awsui_root_1kjc7_1yzbm_167",
  "title": "awsui_title_1kjc7_1yzbm_202",
  "list": "awsui_list_1kjc7_1yzbm_206",
  "marker--dimmed": "awsui_marker--dimmed_1kjc7_1yzbm_255",
  "marker--highlighted": "awsui_marker--highlighted_1kjc7_1yzbm_258"
};
  