
    import './styles.scoped.css';
    export default {
  "dimmed": "awsui_dimmed_10ipo_zz2li_153",
  "marker": "awsui_marker_10ipo_zz2li_205",
  "marker--line": "awsui_marker--line_10ipo_zz2li_245",
  "marker--rectangle": "awsui_marker--rectangle_10ipo_zz2li_249",
  "marker--hollow-rectangle": "awsui_marker--hollow-rectangle_10ipo_zz2li_256",
  "marker--dashed": "awsui_marker--dashed_10ipo_zz2li_272",
  "root": "awsui_root_10ipo_zz2li_291",
  "value": "awsui_value_10ipo_zz2li_323",
  "expandable": "awsui_expandable_10ipo_zz2li_327",
  "list-item": "awsui_list-item_10ipo_zz2li_331",
  "inner-list-item": "awsui_inner-list-item_10ipo_zz2li_332",
  "key-value-pair": "awsui_key-value-pair_10ipo_zz2li_337",
  "expandable-section": "awsui_expandable-section_10ipo_zz2li_338",
  "key": "awsui_key_10ipo_zz2li_337",
  "sub-items": "awsui_sub-items_10ipo_zz2li_351",
  "list": "awsui_list_10ipo_zz2li_331",
  "compact": "awsui_compact_10ipo_zz2li_377",
  "with-sub-items": "awsui_with-sub-items_10ipo_zz2li_382",
  "full-width": "awsui_full-width_10ipo_zz2li_387",
  "announced": "awsui_announced_10ipo_zz2li_391"
};
  