import React, { useState } from 'react'
import { usePut, Box, Button, Checkbox, Select, SpaceBetween, Textarea } from 'rad-framework-ui'
import { Form } from '../common/Form'

const documentOptions = [
  { label: 'Select the document received', value: '', disabled: true },
  { label: 'Report Card', value: 'report-card' },
  { label: 'Shot Record', value: 'shot-record' },
  { label: 'Birth Certificate', value: 'birth-certificate' },
  { label: 'Other', value: 'other' }
]

const documentTypes = documentOptions.reduce((acc, x) => {
  if (!x.disabled) {
    acc[x.value] = x.label
  }
  return acc
}, {})

export const states = {
  init: {
    displayName: 'Not Audited',
    actionLabel: 'Select for audit.',
    statusColor: 'text-status-inactive',
    buttonLabel: 'Notify the client',
    prev: null,
    next: 'selected',
    originalValues: (child) => ({ id: child.id, state: 'init' }),
    canSave: (formValues) => true,
    inputs: (formValues, setFormValues, checked) => null,
    nodes: (child) => null
  },
  selected: {
    displayName: 'Selected for audit',
    actionLabel: 'I\'ve contacted them and requested documents',
    statusColor: 'text-status-warning',
    buttonLabel: 'Save',
    prev: null,
    next: 'requested',
    originalValues: (child) => ({ id: child.id, state: 'selected' }),
    canSave: (formValues) => true,
    inputs: (formValues, setFormValues, checked) => null,
    nodes: (child) => null
  },
  requested: {
    displayName: 'Documents Requested',
    actionLabel: 'I\'ve received documents',
    statusColor: 'text-status-warning',
    buttonLabel: 'Save',
    prev: 'selected',
    next: 'received',
    originalValues: (child) => ({ id: child.id, state: 'requested', document: child.auditDocument, notes: child.auditNotesOther }),
    canSave: (formValues) => {
      return formValues.document != null &&
        (
          formValues.document !== 'other' ||
          (formValues.notes ?? '').length > 0
        )
    },
    inputs: (formValues, setFormValues, checked) => {
      const dropdown = (
        <Select
          ariaRequired
          onChange={({ detail }) => {
            setFormValues({
              ...formValues,
              document: detail.selectedOption.value,
              notes: detail.selectedOption.value === 'other' ? formValues.notes : null
            })
          }}
          expandToViewport
          options={documentOptions}
          selectedOption={formValues.document == null ? null : { label: documentTypes[formValues.document], value: formValues.document }}
          placeholder='Select the document received'
        />
      )
      const notes = (
        <Textarea
          placeholder='Please describe "Other"...'
          ariaRequired
          value={formValues.notes}
          onChange={({ detail }) => { setFormValues({ ...formValues, notes: detail.value }) }}
        />
      )
      return (
        <>
          {checked ? dropdown : null}
          {checked && formValues.document === 'other' ? notes : null}
        </>
      )
    },
    nodes: (child) => null
  },
  received: {
    displayName: 'Documents Received',
    actionLabel: 'I\'ve received documents',
    statusColor: 'text-status-success',
    buttonLabel: 'Save',
    prev: 'requested',
    next: null,
    originalValues: (child) => ({ id: child.id, state: 'received' }),
    canSave: (formValues) => false,
    inputs: (formValues, setFormValues, checked) => null,
    nodes: (child) => {
      const notes = (
        <div>
          Notes:
          <Box variant='pre' margin={{ bottom: 'n' }}>{child.auditNotesOther}</Box>
        </div>
      )
      return (
        <SpaceBetween direction='vertical' size='xs'>
          <div>Document: {documentTypes[child.auditDocument]}</div>
          {child.auditNotesOther == null ? null : notes}
        </SpaceBetween>
      )
    }
  }
}

export function AuditListRowForm ({ child }) {
  const [checked, setChecked] = useState(false)
  const [formValues, setFormValues] = useState(states[child.auditState].originalValues(child))
  const update = usePut('/api/audit', formValues, (resp, payload) => {
    const newValues = states[payload.newState].originalValues(child)
    child.auditDocument = payload.document
    child.auditNotesOther = payload.notes
    setFormValues(newValues)
    setChecked(false)
  })

  const { displayName, actionLabel, buttonLabel, statusColor, prev, next, canSave, inputs, nodes } = states[formValues.state]

  const checkbox = (
    <Checkbox
      ariaRequired
      checked={checked}
      onChange={({ detail }) => { setChecked(detail.checked) }}
    >
      {actionLabel}
    </Checkbox>
  )

  const saveButton = (
    <Button
      onClick={() => {
        const newState = states[formValues.state].next
        const mapper = (payload) => ({ ...payload, newState })
        return update(false, mapper)
      }}
      variant='primary'
      disabled={!(canSave(formValues) && checked)}
      wrapText={false}
    >
      {buttonLabel}
    </Button>
  )

  const undoButton = (
    <Button
      onClick={() => {
        const newState = states[formValues.state].prev
        const mapper = (payload) => {
          // only keep keys present in the new state
          return Object.keys(states[newState].originalValues(child))
            .reduce((acc, k) => {
              if (payload.hasOwnProperty(k)) {
                acc[k] = payload[k]
              }
              return acc
            }, { newState })
        }
        return update(false, mapper)
      }}
      variant='link'
      disabled={false}
      wrapText={false}
    >
      Undo
    </Button>
  )

  return (
    <Form>
      <SpaceBetween direction='vertical' size='xs'>
        <Box variant='div' fontWeight='heavy' color={statusColor}>Status: {displayName}</Box>
        {next == null ? null : checkbox}
        {inputs(formValues, setFormValues, checked)}
        {nodes(child)}
        <SpaceBetween direction='horizontal' size='xs'>
          {next == null ? null : saveButton}
          {prev == null ? null : undoButton}
        </SpaceBetween>
      </SpaceBetween>
    </Form>
  )
}
