import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useGet,
  AppLayout,
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter
} from 'rad-framework-ui'
import { toTitleCase } from '../common/utilities'

export function PassiveEnrollmentUploadDetails () {
  const { passiveEnrollmentUploadId } = useParams()
  const { data: passiveEnrollmentUpload } = useGet(`/api/passive-enrollment-upload/${passiveEnrollmentUploadId}`, true)

  if (passiveEnrollmentUpload == null) return null

  return (
    <AppLayout
      name={`Passive Enrollment Upload # ${passiveEnrollmentUploadId}`}
      contentHeader={
        <Header
          variant='h1'
        >
          Passive Enrollment Upload # {passiveEnrollmentUploadId}
        </Header>
      }
      content={
        <SpaceBetween size='l'>
          <Details passiveEnrollmentUpload={passiveEnrollmentUpload} />
          <Failures passiveEnrollmentUpload={passiveEnrollmentUpload} />
        </SpaceBetween>
      }
    />
  )
}

function Details ({ passiveEnrollmentUpload }) {
  if (passiveEnrollmentUpload == null) return null

  return (
    <Container
      header={
        <Header
          variant='h2'
        >
          Details
        </Header>
      }
    >
      <SpaceBetween size='l'>
        <div>
          <Box variant='awsui-key-label'>
            File
          </Box>
          <div>{passiveEnrollmentUpload.path.split('/').pop()}</div>
        </div>
        <ColumnLayout columns={3} borders='vertical'>
          <div>
            <Box variant='awsui-key-label'>
              Status
            </Box>
            <div>{toTitleCase(passiveEnrollmentUpload.status)}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Date
            </Box>
            <div>{new Date(passiveEnrollmentUpload.updatedAt).toLocaleString()}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              User
            </Box>
            <Link href={`/admin/user/${passiveEnrollmentUpload.updater.id}`}>{passiveEnrollmentUpload.updater.name}</Link>
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Successes
            </Box>
            {passiveEnrollmentUpload.successCount != null ? Number(passiveEnrollmentUpload.successCount).toLocaleString() : '-'}
          </div>
          <div>
            <Box variant='awsui-key-label'>
              Failures
            </Box>
            {passiveEnrollmentUpload.failureCount != null ? Number(passiveEnrollmentUpload.failureCount).toLocaleString() : '-'}
          </div>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  )
}

function Failures ({ passiveEnrollmentUpload }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageLength = 20
  const searchText = searchParams.get('search') ?? ''
  const currentPageIndex = parseInt(searchParams.get('page') ?? 1)
  const [filteringText, setFilteringText] = useState(searchText)
  const { data: failures, count } = useGet(
    `/api/passive-enrollment-upload/failures/${passiveEnrollmentUpload.id}
    ?search=${searchText}
    &offset=${(currentPageIndex - 1) * pageLength}
    &limit=${pageLength}`
  )
  if (passiveEnrollmentUpload == null || failures == null) return null

  return (
    <Container
      header={
        <Header
          variant='h2'
          counter={'(' + Number(count).toLocaleString() + ')'}
        >
          Failures
        </Header>
    }
    >
      <Box padding={{ left: 'xxs', right: 'xxs' }}>
        <Table
          columnDefinitions={[
            {
              id: 'rowNumber',
              header: 'Row',
              cell: item => item.rowNumber
            },
            {
              id: 'message',
              header: 'Message',
              cell: item => item.message
            }
          ]}
          items={failures}
          empty={
            <Box textAlign='center' padding={{ top: 'l' }}>
              <Box>
                No matches found.
              </Box>
            </Box>
    }
          filter={
            <TextFilter
              filteringPlaceholder='Search'
              filteringAriaLabel='Search projects'
              filteringText={filteringText}
              onChange={({ detail }) => setFilteringText(detail.filteringText)}
              onDelayedChange={({ detail }) => {
                searchParams.delete('page')
                if (detail.filteringText.trim() !== '') {
                  searchParams.set('search', detail.filteringText)
                } else {
                  searchParams.delete('search')
                }
                setSearchParams(searchParams)
              }}
            />
      }
          pagination={
            <Pagination
              currentPageIndex={currentPageIndex}
              pagesCount={Math.ceil(count / pageLength)}
              onChange={({ detail }) => {
                if (detail.currentPageIndex === 1) {
                  searchParams.delete('page')
                } else {
                  searchParams.set('page', detail.currentPageIndex)
                }
                setSearchParams(searchParams)
              }}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: pageNumber => `Page ${pageNumber} of all pages`
              }}
            />
      }
          variant='borderless'
        />
      </Box>
    </Container>
  )
}
