import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toTitleCase } from 'titlecase'
import { useGet, AppLayout, Box, Button, Grid, Header, Link, Pagination, Select, SpaceBetween, Table, TextFilter } from 'rad-framework-ui'
import { formatDate, formatDateTime } from '../common/utilities'

export function ApplicationList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [exportingApplications, setExportingApplications] = useState(false)
  const [exportingContacts, setExportingContacts] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [searchStatus, setSearchStatus] = useState(searchParams.get('status') ?? '')
  const [provider, setProvider] = useState(searchParams.get('provider') ?? '')
  const { data: applications, count } = useGet(
    '/api/application' +
    `?search=${encodeURIComponent(searchText)}` +
    `&status=${searchStatus}` +
    `&provider=${provider}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: exportedApplications } = useGet(
    exportingApplications
      ? '/api/application' +
      `?search=${encodeURIComponent(searchText)}` +
      `&status=${searchStatus}` +
      `&provider=${provider}` +
      '&limit=50000'
      : null
  )
  const { data: exportedContacts } = useGet(
    exportingContacts
      ? '/api/application' +
      `?search=${encodeURIComponent(searchText)}` +
      '&status=accepted' +
      `&provider=${provider}` +
      '&limit=50000'
      : null
  )

  const ebtProviderOptions = [
    { label: 'All providers', value: '' },
    { label: 'Cherokee', value: 'Cherokee' },
    { label: 'Chickasaw', value: 'Chickasaw' }
  ]

  const statusOptions = [
    { label: 'All statuses', value: '' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Needs Verification', value: 'needs verification' },
    { label: 'Verified', value: 'verified' },
    { label: 'Escalated', value: 'escalated' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Rejected', value: 'rejected' }
  ]

  const getColor = (item) => {
    switch (item?.status) {
      case 'accepted':
        return 'text-status-success'
      case 'rejected':
        return 'text-status-error'
      case 'escalated':
        return 'text-status-warning'
      default:
        return null
    }
  }

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === searchText.toLowerCase()
            ? <span key={i} className='highlight'>{part}</span>
            : part
        )}
      </span>
    )
  }

  useEffect(() => {
    if (exportingApplications && exportedApplications != null) {
      exportApplicationsToExcel()
      setExportingApplications(false)
    }
    if (exportingContacts && exportedContacts != null) {
      exportContactsToExcel()
      setExportingContacts(false)
    }
  }, [exportingApplications, exportingContacts, exportedApplications, exportedContacts])

  function exportApplicationsToExcel () {
    const data = exportedApplications.map(x => ([
      '',
      formatDate(x.created_at),
      formatDate(x.status_date),
      '',
      '',
      '',
      '',
      '',
      x.status_reason ?? '-',
      x.children.map(y =>
        [y.first_name, y.middle_name, y.last_name].filter(z => z != null).join(' ') + ' - ' + formatDate(y.birth_date))
        .join('\n'),
      '',
      '',
      '',
      x.id.toString(),
      [x.first_name, x.middle_name, x.last_name].filter(z => z != null).join(' '),
      x.email ?? '-',
      [x.phone1, x.phone2].filter(z => z != null).join('\n') ?? '-',
      [x.line1, x.line2].filter(y => y != null).join('\n') + '\n' + [x.city, x.state, x.zip].join(' '),
      x.children.every(y => y.school_district_name === x.children[0].school_district_name)
        ? x.children[0].school_district_name
        : x.children.map(y => y.school_district_name).join('\n'),
      x.status != null ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : 'Submitted'
    ]))
    const header = [
      'Status',
      'Date Submitted',
      'Status Date',
      'Date Entered',
      'HFO Staff',
      'Date Contacted',
      'Contact Method',
      'Date Accepted',
      'Reason',
      'Child(ren) Name and Birthdate',
      'Case Notes',
      'Disposition',
      'Action Taken',
      'Case Number',
      'Parent Name',
      'Parent Email',
      'Parent Phone',
      'Address',
      'School District',
      'Application Status'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_applications_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  function exportContactsToExcel () {
    const data = exportedContacts.map(x => {
      const oldestChild = x.children.filter(y => !y.excluded).reduce((a, b) => a.birth_date < b.birth_date ? a : b)
      return ([
        x.first_name ?? '',
        x.middle_name ?? '',
        x.last_name ?? '',
        [oldestChild.first_name, oldestChild.middle_name, oldestChild.last_name].filter(z => z != null).join(' '),
        formatDate(oldestChild.birth_date),
        x.id.toString(),
        oldestChild.school_district_name,
        x.line1 ?? '',
        x.line2 ?? '',
        x.city ?? '',
        x.state ?? '',
        x.zip ?? '',
        x.phone1 ?? '',
        x.phone2 ?? '',
        x.email ?? ''
      ])
    })
    const header = [
      'Parent First Name',
      'Parent Middle Name',
      'Parent Last Name',
      'Oldest Child Name',
      'Oldest Child DOB',
      'Case Number',
      'District',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Zip',
      'Parent Primary Phone',
      'Parent Secondary Phone',
      'Parent Email'
    ]
    data.unshift(header)

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `sebt_accepted_application_contacts_${year}_${month}_${date}.xlsx`

    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts')
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  if (
    applications != null
  ) {
    return (
      <AppLayout
        contentHeader={
          <Header
            variant='h1'
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={() => navigate(`/admin/apply?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`)}>Create</Button>
                <Button onClick={() => setExportingApplications(true)}>Export Applications</Button>
                <Button onClick={() => setExportingContacts(true)}>Export Accepted Application Contacts</Button>
              </SpaceBetween>
            }
            counter={'(' + Number(count).toLocaleString() + ')'}
            description={
              <SpaceBetween size='xxs'>
                <div>You can search by application number, primary contact name, primary contact birthdate, primary contact phone number, child name, child birthdate or school district.</div>
                <div>Exports are limited to 50,000 records.</div>
              </SpaceBetween>
            }
          >
            Applications
          </Header>
        }
        content={
          <Table
            columnDefinitions={[
              {
                id: 'id',
                header: '#',
                cell: item => <Link href={`/admin/application/${item.id}`}>{highlightMatch(item.id.toString())}</Link>
              },
              {
                id: 'primaryContact',
                header: 'Primary Contact',
                cell: item => <div>{highlightMatch(item.first_name + ' ' + (item.middle_name ?? '') + ' ' + item.last_name)} - {highlightMatch(formatDate(item.birth_date))}</div>
              },
              {
                id: 'primaryContactPhone',
                header: 'Primary Contact Phone / Email',
                cell: item =>
                  <div>
                    <div>{highlightMatch(item.phone1)}{(item.phone2 != null ? highlightMatch(' | ' + item.phone2) : '')}</div>
                    <div>{highlightMatch(item.email)}</div>
                  </div>
              },
              {
                id: 'children',
                header: 'Children',
                cell: item =>
                  item.children.map((x, index) =>
                    <Box key={'child-' + index}>{highlightMatch(x.first_name + ' ' + (x.middle_name ?? '') + ' ' + x.last_name)} - {highlightMatch(formatDate(x.birth_date))}</Box>)
              },
              {
                id: 'status',
                header: 'Status',
                cell: item => <Box color={getColor(item)}>{toTitleCase(item.status ?? 'submitted')}</Box>
              },
              {
                id: 'createdAt',
                header: 'Created',
                cell: item => formatDateTime(item.created_at)
              },
              {
                id: 'actions',
                cell: item => <Button disabled={item.status === 'accepted'} variant='inline-icon' iconName='edit' href={`/admin/application/${item.id}/edit?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`} />

              }
            ]}
            items={applications}
            variant='full-page'
            filter={
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <TextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search participants'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.delete('page')
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <Select
                  onChange={({ detail }) => {
                    setSearchStatus(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('status')
                    } else {
                      searchParams.set('status', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={statusOptions}
                  selectedOption={statusOptions.find((x) => x.value === searchStatus) ?? statusOptions[0]}
                  placeholder='Choose status'
                />
                <Select
                  onChange={({ detail }) => {
                    setProvider(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('provider')
                    } else {
                      searchParams.set('provider', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={ebtProviderOptions}
                  selectedOption={ebtProviderOptions.find((x) => x.value === provider) ?? ebtProviderOptions[0]}
                  placeholder='Choose EBT provider'
                />
              </Grid>
            }
            pagination={
              <Pagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <Box textAlign='center' color='inherit'>
                No matches found.
              </Box>
            }
          />
        }
      />
    )
  }
}
